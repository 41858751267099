import { ButtonHTMLAttributes, ComponentPropsWithRef, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Slot } from '@radix-ui/react-slot';

export const buttonVariants = tv({
    base: [
        'box-border flex items-center justify-center gap-1',
        'cursor-pointer border-none transition',
        'font-default text-center font-semibold',
        'disabled:cursor-not-allowed',
        '[&_i]:flex [&_i]:items-center [&_i]:justify-center [&_i]:font-bold',
    ],

    variants: {
        styleGuide: {
            coremedia: [
                'h-[22px] w-auto rounded-full px-[15px] text-[11px] leading-[20px] tracking-[0.85px] outline-none',
                'delay-200 ease-in-out',
            ],

            new: [
                'rounded-xs min-w-[88px] text-xs',
                'enabled:focus:outline-1 enabled:focus:outline-red-500',
                'disabled:text-gray-250 disabled:bg-gray-50',
            ],
        },

        variant: {
            primary: [],

            secondary: [],

            tertiary: ['bg-green-500 text-white', 'enabled:hover:bg-green-600 enabled:focus-visible:bg-green-600'],

            clean: [],

            boxed: [],

            icon: ['bg-transparent', 'rounded-none'],
        },

        size: {
            sm: ['h-6', '[&_i]:w-5'],

            md: ['[&_i]:w-6 [&_i]:text-xs'],

            lg: ['h-12 text-sm [&_i]:w-[1.625rem]'],
        },
    },

    compoundVariants: [
        {
            styleGuide: 'new',
            variant: 'primary',
            className: ['bg-gray-850 text-white', 'enabled:hover:bg-black'],
        },
        {
            styleGuide: 'new',
            variant: 'secondary',
            className: [
                'text-gray-850 bg-gray-50',
                'enabled:hover:shadow-button/secondary enabled:hover:bg-white enabled:hover:text-red-500',
                'enabled:data-[state=open]:shadow-button/secondary enabled:data-[state=open]:bg-white enabled:data-[state=open]:text-red-500',
            ],
        },
        {
            styleGuide: 'new',
            variant: 'icon',
            className: [
                'h-auto min-w-4 px-0',
                'bg-transparent',
                '[&_i]:text-4 [&_i]:w-4 [&_i]:font-normal',
                'disabled:bg-transparent',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'primary',
            className: [
                'bg-coremedia-blue-500 text-white',
                'enabled:hover:bg-coremedia-blue-600 enabled:focus-visible:bg-coremedia-blue-600',
                'disabled:bg-coremedia-blue-400',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'secondary',
            className: [
                'border-coremedia-blue-500 text-coremedia-blue-600 border border-solid bg-white',
                'enabled:hover:border-coremedia-blue-600 enabled:hover:bg-coremedia-blue-100 enabled:hover:text-coremedia-blue-600 enabled:hover:shadow-none',
                'enabled:focus-visible:border-coremedia-blue-600 enabled:focus-visible:bg-coremedia-blue-100 enabled:focus-visible:text-coremedia-blue-600 enabled:focus-visible:shadow-none',
                'disabled:border-coremedia-blue-400 disabled:text-coremedia-blue-400 disabled:bg-white',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'clean',
            className: [
                'rounded-xs text-coremedia-blue-600 bg-transparent',
                'enabled:hover:bg-coremedia-blue-100 enabled:focus-visible:bg-coremedia-blue-100',
                'disabled:text-coremedia-blue-400 disabled:bg-transparent',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'boxed',
            className: [
                'text-coremedia-grey-700 h-[32px] bg-white px-[10px] font-medium leading-[32px]',
                'rounded-xs border-coremedia-grey-100 border border-solid',
                '[&_i]:text-coremedia-grey-600 [&_i]:transition [&_i]:delay-200 [&_i]:ease-in-out',
                'enabled:hover:[&_i]:text-coremedia-turquoise-500 enabled:focus-visible:[&_i]:text-coremedia-turquoise-500',
                'enabled:hover:border-coremedia-turquoise-500 enabled:hover:bg-coremedia-blue-100',
                'enabled:focus-visible:border-coremedia-turquoise-500 enabled:focus-visible:bg-coremedia-blue-100',
                'disabled:border-coremedia-grey-400 disabled:text-coremedia-grey-400 disabled:bg-white disabled:font-light',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'icon',
            className: [
                'h-auto min-w-4 px-0',
                'text-coremedia-grey-700 bg-transparent',
                '[&_i]:text-4 [&_i]:w-4 [&_i]:font-normal',
                'enabled:hover:text-coremedia-grey-500 enabled:focus-visible:text-coremedia-grey-500',
                'disabled:bg-transparent',
            ],
        },
        {
            styleGuide: 'coremedia',
            size: 'sm',
            className: ['text-[10px] leading-[0.5px]'],
        },
        {
            styleGuide: 'new',
            size: 'sm',
            className: ['text-xxs px-4'],
        },
        {
            styleGuide: 'new',
            size: 'md',
            className: ['h-8 px-6'],
        },
        {
            styleGuide: 'new',
            size: 'lg',
            className: ['px-8'],
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        variant: 'primary',
        size: 'md',
    },
});

type CustomButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonVariants> & {
        asChild?: boolean;
    };

export const Button = forwardRef<HTMLButtonElement, CustomButtonProps>(
    ({ styleGuide, variant, size, className, asChild, type, ...rest }, forwardedRef) => {
        const Comp = asChild ? Slot : 'button';

        return (
            <Comp
                type={type ?? (asChild ? undefined : 'button')}
                ref={forwardedRef}
                className={buttonVariants({ styleGuide, variant, size, className })}
                {...rest}
            />
        );
    },
);

Button.displayName = 'Button';

export type ButtonProps = ComponentPropsWithRef<typeof Button>;
