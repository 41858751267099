import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
    type SyntheticEvent,
    type ChangeEvent,
    type KeyboardEvent,
    type ReactNode,
} from 'react';

type InlineEditContextProps = {
    keepFocusOnOutsideClick: boolean;
    disabled: boolean;
    inputValue: string;
    isEditing: boolean;
    handleOnFocus: (e: SyntheticEvent) => void;
    handleOnBlur: (e: SyntheticEvent) => void;
    handleSaveButton: (e: SyntheticEvent) => void;
    handleCancelButton: (e: SyntheticEvent) => void;
    handleRestoreButton: (e: SyntheticEvent) => void;
    handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const InlineEditContext = createContext<InlineEditContextProps | null>(null);

export type InlineEditContextProviderProps = {
    defaultValue: string;
    value: string;
    allowEmptyValue: boolean;
    keepFocusOnOutsideClick: boolean;
    disabled: boolean;
    onSave?: (savedValue: string, e?: SyntheticEvent) => void;
    onCancel?: (e?: SyntheticEvent) => void;
    onRestore?: (e?: SyntheticEvent) => void;
    onEdit?: (e: SyntheticEvent) => void;
    onChange?: (newValue: string, event?: SyntheticEvent) => void;
    children: ReactNode;
};

export function InlineEditContextProvider({
    defaultValue,
    value,
    allowEmptyValue,
    keepFocusOnOutsideClick,
    disabled,
    onSave,
    onCancel,
    onRestore,
    onEdit,
    onChange,
    children,
}: InlineEditContextProviderProps) {
    const [inputValue, setInputValue] = useState(value || defaultValue);
    const [originalValue, setOriginalValue] = useState<typeof value>('');
    const [isEditing, setIsEditing] = useState(false);
    const isEditingRef = useRef(false);
    const isOutsideClickRef = useRef(true);

    const handleOnFocus = useCallback(
        (e: SyntheticEvent) => {
            if (!isEditingRef.current) {
                onEdit?.(e);
                setOriginalValue(inputValue);

                isEditingRef.current = true;
            }

            setIsEditing(true);
        },
        [inputValue, onEdit],
    );

    const handleOnBlur = useCallback(
        (e: SyntheticEvent) => {
            setTimeout(() => {
                if (isOutsideClickRef.current) {
                    onCancel?.(e);
                    onChange?.(originalValue, e);
                    setInputValue(originalValue);
                    setIsEditing(false);
                }

                isOutsideClickRef.current = true;
            }, 200);
        },
        [onCancel, onChange, originalValue],
    );

    const handleSaveButton = useCallback(
        (e: SyntheticEvent) => {
            isEditingRef.current = false;
            isOutsideClickRef.current = false;

            setIsEditing(false);
            if (allowEmptyValue || inputValue !== '') {
                onSave?.(inputValue, e);
                setOriginalValue(inputValue);
            } else {
                setInputValue(originalValue);
                onChange?.(originalValue);
            }

            (window.document.activeElement as HTMLInputElement)?.blur?.();
        },
        [allowEmptyValue, inputValue, onChange, onSave, originalValue],
    );

    const handleCancelButton = useCallback(
        (e: SyntheticEvent) => {
            isEditingRef.current = false;
            isOutsideClickRef.current = false;

            setIsEditing(false);
            setInputValue(originalValue);
            onChange?.(originalValue, e);
            onCancel?.(e);

            (window.document.activeElement as HTMLInputElement)?.blur?.();
        },
        [onCancel, onChange, originalValue],
    );

    const handleRestoreButton = useCallback(
        (e: SyntheticEvent) => {
            isEditingRef.current = false;
            isOutsideClickRef.current = false;

            setIsEditing(false);
            onRestore?.(e);

            (window.document.activeElement as HTMLInputElement)?.blur?.();
        },
        [onRestore],
    );

    const handleOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const currentValue = e.target.value;

            setInputValue(currentValue);
            onChange?.(currentValue, e);
        },
        [onChange],
    );

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            switch (e.key) {
                case 'Enter': {
                    handleSaveButton(e as unknown as SyntheticEvent);
                    break;
                }
                case 'Tab': {
                    isOutsideClickRef.current = false;
                    break;
                }
            }
        },
        [handleSaveButton],
    );

    useEffect(() => {
        const controller = new AbortController();

        window.addEventListener(
            'keydown',
            (e) => {
                if (isEditingRef.current) {
                    switch (e.key) {
                        case 'Escape': {
                            isOutsideClickRef.current = false;

                            handleCancelButton(e as unknown as SyntheticEvent);
                            break;
                        }
                    }
                }
            },
            {
                signal: controller.signal,
            },
        );

        return () => {
            controller.abort();
        };
    }, [handleCancelButton]);

    useEffect(() => {
        setInputValue(value || defaultValue);
    }, [defaultValue, value]);

    const inlineEditContextValue = useMemo(
        () => ({
            keepFocusOnOutsideClick,
            disabled,
            inputValue,
            isEditing,
            handleOnFocus,
            handleOnBlur,
            handleSaveButton,
            handleCancelButton,
            handleRestoreButton,
            handleOnChange,
            handleKeyDown,
        }),
        [
            keepFocusOnOutsideClick,
            disabled,
            inputValue,
            isEditing,
            handleOnFocus,
            handleOnBlur,
            handleSaveButton,
            handleCancelButton,
            handleRestoreButton,
            handleOnChange,
            handleKeyDown,
        ],
    );

    return <InlineEditContext.Provider value={inlineEditContextValue}>{children}</InlineEditContext.Provider>;
}

export function useInlineEdit(componentName: string) {
    const inlineEditContext = useContext(InlineEditContext);

    if (!inlineEditContext) {
        throw new Error(`InlineEdit.${componentName} has to be inside the InlineEdit.Root component`);
    }

    return inlineEditContext;
}
