import { ComponentPropsWithRef, forwardRef, HTMLAttributes } from 'react';
import { LeftColumnItemProps, Scheduler, SchedulerProps, LeftColumn } from '@react-scheduler/react-scheduler';
import { Tooltip } from '@components/Tooltip';
import { useHasOverflowed } from '@sidetalk/hooks';

const CustomRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => <div ref={forwardedRef} className={className} {...rest} />,
);

CustomRoot.displayName = 'ReactScheduler.Root';

function LeftColumnItem({ id, item, rows, onItemClick }: LeftColumnItemProps) {
    const [titleRef, hasOverflowed] = useHasOverflowed();

    return (
        <Tooltip.Provider>
            <LeftColumn.Wrapper
                clickable={typeof onItemClick === 'function'}
                rows={rows}
                onClick={() => onItemClick?.({ id, label: item })}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ref={titleRef}
                className="[&_p]:focus:!text-coremedia-blue-600 [&_p]:hover:!text-coremedia-blue-600 [&_p]:hover:!font-semibold [&_p]:focus:!font-semibold"
            >
                <LeftColumn.InnerWrapper className="h-full">
                    <LeftColumn.TextWrapper className="gap-1">
                        {item.type && (
                            <LeftColumn.Text
                                className="font-default !border-coremedia-blue-600 !text-coremedia-blue-600 text-[0.421rem]"
                                isDistribution
                            >
                                {item?.type ?? 'Distribution'}
                            </LeftColumn.Text>
                        )}
                        <Tooltip.Basic content={hasOverflowed ? item.title : undefined}>
                            <LeftColumn.Text className="font-default h-[18px] place-content-center !pr-3" isMain>
                                {item.title}
                            </LeftColumn.Text>
                        </Tooltip.Basic>
                    </LeftColumn.TextWrapper>
                </LeftColumn.InnerWrapper>
            </LeftColumn.Wrapper>
        </Tooltip.Provider>
    );
}

LeftColumnItem.displayName = 'LeftColumnItem';

function HeaderComponent() {
    return <div className="font-default h-[18px] pl-4 text-xs font-bold">Skills</div>;
}

HeaderComponent.displayName = 'HeaderComponent';

export const ReactSchedulerBasic = forwardRef<HTMLDivElement, SchedulerProps>((props, forwardedRef) => {
    return (
        <CustomRoot ref={forwardedRef}>
            <Scheduler
                config={{ zoom: 2, showTooltip: false }}
                HeaderComponent={HeaderComponent}
                LeftColumnItemComponent={LeftColumnItem}
                {...props}
            />
        </CustomRoot>
    );
});

export type ReactSchedulerProps = ComponentPropsWithRef<typeof ReactSchedulerBasic>;

ReactSchedulerBasic.displayName = 'ReactScheduler';

export const ReactScheduler = {
    Basic: ReactSchedulerBasic,
    Root: CustomRoot,
    HeaderComponent: HeaderComponent,
    LeftColumnItem: LeftColumnItem,
    Scheduler: Scheduler,
};
